.footer__main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 57px calc((100% - 185px) / 7.9);
  background: #2b9aeb;
}

.footer__main > div.footer__innerMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1700px;
}

.footer__main > div.footer__innerMain > div {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 24px;
  flex: 1;
}

.footer__main > div.footer__innerMain > div a#rebase-logo-footer {
  display: flex;
  width: fit-content;
}

.footer__main > div.footer__innerMain > div > span {
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  width: 60%;
}

.footer__main > div.footer__innerMain > div > div.socials {
  width: 176px;
}

.footer__main > div.footer__innerMain > div > nav {
  padding-left: 16px;
  padding-top: 10px;
  /* margin-left: 50px; */
}

.footer__main > div.footer__innerMain > div > nav ul {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding-left: 0;
  flex-wrap: wrap;
  /* flex-wrap: wrap;
  height: 160px; */
}

.footer__main > div.footer__innerMain > div > nav li {
  /* margin-left: 50px; */
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  list-style: none;
  flex: 1 1 45%;
}

.footer__main button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 32px;
  gap: 8px;
  width: 212px;
  height: 48px;
  border-radius: 4px;
  border: none;

  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
  color: #2184d0;
  cursor: pointer;
}

.footer__main button.desktop {
  display: flex;
  right: 16.11%;
}

.footer__main button.mobile {
  display: none;
}

@media (max-width: 1000px) {
  .footer__main {
    padding-top: 32px;
    padding-right: 7%;
    padding-left: 4%;
  }

  .footer__main > div.footer__innerMain > div {
    gap: 16px;
  }

  .footer__main > div.footer__innerMain > div > span {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 24px;
  }

  .footer__main > div.footer__innerMain > div div.socials {
    padding-left: 20px;
    width: 100%;
    max-width: 500px;
  }

  .footer__main > div.footer__innerMain > div div.socials > app-socials {
    padding-top: 10px;
    width: 100%;
  }

  .footer__main > div.footer__innerMain > div > nav {
    padding-left: 0;
  }

  .footer__main > div.footer__innerMain > div > nav li {
    font-weight: 400;
    font-size: 1rem;
    line-height: 48px;
  }

  .footer__main button.desktop {
    display: none;
  }

  .footer__main button.mobile {
    display: flex;
    width: 48px;
    padding: 0;
    right: 7%;
  }
}
