.menu__main-container {
  display: flex;
  justify-content: center;
  z-index: 11000;
}

.menu__main {
  z-index: 11000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  will-change: transform;
  height: 80px;
  width: 100%;
  padding: 0 calc((100% - 185px) / 7.9);
  background: rgba(162, 197, 222, 0.25);
  backdrop-filter: blur(16px);
  white-space: nowrap;
}

.menu__rebase-logo {
  max-width: 366px;
  width: 70vw;
}

.menu__main.alternativeMenu {
  margin-top: 31px;
  width: 879px;
  padding: 0;
}

div.menu__wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 1700px;
}

div.menu__left-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 40px;
}

div.menu__left-side a#rebase-logo-header {
  display: flex;
}

.menu__app-main-menu {
  /* display: flex;
  gap: 20px; */
  width: 78%;
}

button.menu__get-app {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 32px;
  gap: 8px;

  width: 212px;
  min-width: 212px;
  height: 48px;

  border-radius: 4px;

  border: none;

  font-weight: 600;
  font-size: 1rem;
  line-height: 22px;
}

img.menu__hamburger {
  display: none;
}

div.menu__hamburger-menu {
  display: none;
}

@media (max-width: 1200px) {
  .menu__main {
    justify-content: center;
  }
}

@media (max-width: 1050px) {
  .menu__main.alternativeMenu {
    margin-top: 0;
    width: 100%;
  }

  div.menu__left-side .menu__app-main-menu {
    display: none;
  }

  div.menu__left-side nav.menu {
    display: none;
  }

  .menu__main button.menu__get-app {
    display: none;
  }

  img.menu__hamburger {
    display: flex;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .main.menu__in-hamburger-menu {
    background: none;
    backdrop-filter: none;
    z-index: 11000;
  }

  div.menu__hamburger-menu {
    position: fixed;
    top: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: rgba(43, 154, 235, 0.25);
    backdrop-filter: blur(16px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  div.menu__hamburger-menu > .menu__app-socials {
    width: 80%;
    max-width: 355px;
  }

  div.menu__hamburger-menu > div.menu__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 80px;
  }

  div.menu__hamburger-menu > div.menu__top > .menu__app-main-menu {
    width: 100%;
  }

  div.menu__hamburger-menu > div.menu__top > button.menu__get-app {
    width: 90%;
    min-width: none;
    max-width: 400px;
  }
}
