.layout__main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  background: #f0f0ff;
}

.layout__content {
  flex: 1;
  margin-top: 0px;
  background: linear-gradient(162deg, #06b4da 12.92%, #742fab 98.38%),
    linear-gradient(162deg, #06b4da 13.05%, #742fab 80.58%), #ef1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .layout__content {
    /* margin-top: 135px; */
  }
}
