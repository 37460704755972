.menu_nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 23px;
  font-size: 20px;
}

@media (max-width: 1050px) {
  .menu_nav {
    padding: 22px calc((100% - 185px) / 7.9) 48px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    gap: 48px;
  }
}
