.menu__item__menuItemButton {
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px;

  font-weight: 500;
  font-size: 1.25rem;
  line-height: 24px;
  color: white;
}

.menu__item__menuItemButton:hover {
  background: rgba(245, 245, 245, 0.1);
}

.menu__item__current {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
  text-decoration-skip-ink: none;
  text-decoration-color: rgba(255, 255, 255, 0.25);
}

/* ------ submenu ------- */

.menu__item__submenu {
  position: relative;
}

.menu__item__submenuButton {
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  border-radius: 24px;
  padding: 0;

  font-weight: 500;
  font-size: 1.25rem;
  line-height: 24px;
}

.menu__item__left-side {
  right: 0;
}

.menu__item__optionList {
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;

  text-wrap: nowrap;
}

.menu__item__optionList > div {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 12px;
}

.menu__item__optionList > div a {
  cursor: pointer;
}

.menu__item__optionList > div a,
.menu__item__optionList > div span {
  color: #2b9aeb;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
}

.menu__item__optionList > div > div {
  display: flex;
  align-items: center;
  gap: 2px;
}

.menu__item__optionList > div > div > span {
  font-size: 0.75rem;
}

.menu__item__optionList > div > div > img {
  width: 12px;
  height: 12px;
}

.menu__item__optionList .current a {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
  text-decoration-skip-ink: none;
  text-decoration-color: #2b9aeb;
}

/* ---------------------- */

@media (max-width: 1000px) {
  .menu__item__submenu > .menu__item__submenuButton {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 24px;
  }

  .menu__item__menuItemButton {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 24px;
  }

  .menu__item__optionList {
    right: 0;
  }
}
