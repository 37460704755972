.bridge-css-changer > div > div.MuiBox-root {
  margin-top: 0px;
  min-height: 0px;
  padding: 0;
  background-color: white;
}

.bridge-css-changer > div > div.MuiBox-root > div {
  margin-top: 0;
}

.bridge-css-changer > div > div.MuiBox-root > div > div > button:nth-child(1) {
  border: 1px solid #e2e8f0;
}

.bridge-css-changer > div > div.MuiBox-root > div > div > button:nth-child(3) {
  border: 1px solid #e2e8f0;
}

.tab__container {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 530px) {
  .bridge-css-changer {
    width: 350px;
  }
}
