.socials__main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.socials__main a {
  width: 40px;
  height: 40px;
  padding: 4px;
  border-radius: 8px;
}

.socials__main img {
  width: 32px;
  height: 32px;
}

.socials__main img:hover {
  background: rgba(255, 255, 255, 0.15);
}
